import { createRouter, createWebHashHistory } from "vue-router"
import coachRoutes from "./pages/coach/route.js"

const routes = [
    ...coachRoutes
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

export default router