import moment from "moment"

export default {
    formatDate({
        dateString,
        originFormat,
        targetType
    }){
        const momentDate = moment(dateString, originFormat)
    
        if(targetType == 'SHORT-MONTH'){
            return momentDate.format('MMM YYYY')
        }
        if(targetType == 'LONG-MONTH'){
            return momentDate.format('MMMM YYYY')
        }
        if(targetType == 'SHORT-DATE'){
            return momentDate.format('DD MMM YYYY')
        }
        if(targetType == 'LONG-DATE'){
            return momentDate.format('DD MMMM YYYY')
        }
        if(targetType == 'DAY-NAME'){
            return momentDate.format('dddd')
        }
    },
    formatDateID({
        dateString,
        originFormat,
        targetType
    }){
        moment.locale('ID')

        const momentDate = moment(dateString, originFormat)
    
        if(targetType == 'SHORT-MONTH'){
            return momentDate.format('MMM YYYY')
        }
        if(targetType == 'LONG-MONTH'){
            return momentDate.format('MMMM YYYY')
        }
        if(targetType == 'SHORT-DATE'){
            return momentDate.format('DD MMM YYYY')
        }
        if(targetType == 'LONG-DATE'){
            return momentDate.format('DD MMMM YYYY')
        }
        if(targetType == 'DAY-NAME'){
            return momentDate.format('dddd')
        }
    }

}