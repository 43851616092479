<template>
    <div v-if="this.selectedProgram.length > 0">
        <v-row
            justify="center"
            class="mb-8 mt-8"
        >
            <v-col cols="12" md="3" class="pa-0">
                <v-btn
                    :max-height="30"
                    style="width:100%"
                    @click="selectIndonesiaProgram"
                >Bahasa Indonesia</v-btn>
            </v-col>
            <v-col cols="12" md="3" class="pa-0">
                <v-btn
                    color="rgba(136, 148, 100, 1)"
                    :max-height="30"  
                    style="width:100%"  
                    @click="selectEnglishProgram"
                >English Program</v-btn>
            </v-col>
        </v-row>
        <div 
            class="mx-auto d-flex flex-row justify-center flex-wrap"
            style="max-width: 700px"
        >
            <div
                v-for="(item, index) in selectedProgram"
                :key="index"
                class="mb-3 coach-box"
                @click="goToDetailPage(item.user_id)"
            >
                <v-img
                    :src="item.picture"
                    :width="130"
                    :height="220"
                    style="border-radius:12px;"
                    cover
                ></v-img>
                <div class="coach-info">
                    <p style="font-size: 12px; text-decoration: underline;">Coach</p>
                    <p style="font-size: 18px;">{{ item.nickname }}</p>
                    <p style="font-size: 14px;">{{ item.domicile }}</p>
                    <p style="font-size: 12px; text-decoration: underline;">{{ item.program == 'Bahasa Indonesia' ? 'Lihat Lebih Detail' : 'See More'}}</p>
                </div>
            </div>
            <div
                v-for="n in itemsPerRow - totalPictureInLastRow"
                :key="n"
                class="mb-3 coach-box"
            ></div>
        </div>
        <div class="bg-white" style="height:40px"></div>
    </div>
    <div v-else>
        <v-progress-circular
            color="rgba(136, 148, 100, 1)"
            indeterminate
            :size="100"
            class="mt-8"
        ></v-progress-circular>
    </div>
</template>

<script>
    import coachService from '@/services/coach/coachService.js'
    import TARGET_URL from "@/config/target-site-config.js"

    export default {
        data(){
            return {
                englishProgram: [],
                indonesiaProgram: [],
                selectedProgram: [],
                TARGET_URL: TARGET_URL,

                browserWidth: 0,
                itemsPerRow: 5,
                totalPictureInLastRow: 0,
                picturesIndexInLastRow: [],
            }
        },
        created(){
            this.loadData()
        },
        mounted() {
            window.addEventListener('resize', this.handleResize)
        },
        methods:{
            async loadData(){
                await this.fetchCoachList()
                this.handleResize()
            },
            async fetchCoachList(){
                try {
                    const response = await coachService.getCoachList()

                    if(response.data.status == true){
                        this.englishProgram = response.data.data.english_program
                        this.indonesiaProgram = response.data.data.indonesia_program
                        this.selectedProgram = this.indonesiaProgram
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            selectEnglishProgram(){
                this.selectedProgram = this.englishProgram
            },
            selectIndonesiaProgram(){
                this.selectedProgram = this.indonesiaProgram
            },
            goToDetailPage(id){
                const url = `${this.TARGET_URL}detail-pelatih/?id=${id}`
                window.open(url, '_blank')
            },
            handleResize() {
                this.picturesIndexInLastRow = []
                this.browserWidth = window.innerWidth >= 720 ? 720 : window.innerWidth
                this.itemsPerRow = Math.floor((this.browserWidth - 20) / 140)
                this.totalPictureInLastRow = this.selectedProgram.length % this.itemsPerRow
                
                if(this.totalPictureInLastRow == 0){
                    this.totalPictureInLastRow = this.itemsPerRow
                }

                for(let i = this.totalPictureInLastRow - 1; i >= 0; i--){
                    this.picturesIndexInLastRow.push(this.selectedProgram.length - 1 - i)
                }
            },
        }
    }
</script>
<style scoped>
    .coach-box{
        position:relative;
        width:140px !important;
    }
    .coach-info{
        position: absolute;
        top:0;
        right:0;
        left:0;
        bottom:0;
        margin: 0 auto;
        padding-top:150px;
        color:white;
    }
    .coach-box p{
        line-height: 16px;
    }
</style>