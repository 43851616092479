<template>
    <div class="bg-div" v-if="coachDetail">
        <div class="detail-container mb-8">
            <div class="mb-4 hidden-sm-and-down">
                <h1 class="text-h4 font-weight-bold text-left">{{ coachDetail.heading }}</h1>
                <h2 class="text-h5 font-weight-medium text-left">{{ coachDetail.subheading }}</h2>
            </div>
            <div class="mb-4 hidden-md-and-up">
                <h1 class="text-h5 font-weight-bold text-left">{{ coachDetail.heading }}</h1>
                <h2 class="text-h6 font-weight-medium text-left">{{ coachDetail.subheading }}</h2>
            </div>

            <LiteYouTubeEmbed v-if="extractVideoID(coachDetail.video_url) != null"
                :id="extractVideoID(coachDetail.video_url)"
                title="Rick Astley - Never Gonna Give You Up (Official Music Video)"
            />

            <v-btn 
                class="w-75 mt-6"
                flat
                :color="coachDetail.upcoming_batch != null ? 'rgba(255, 116, 84, 1)' : 'rgba(168, 168, 168, 1)' "
                :disabled="!coachDetail.upcoming_batch"
                @click="goToRegistrationPage"
            >
                JOIN AS MEMBER
            </v-btn>

            <div class="hidden-sm-and-down mt-8">
                <div class="d-flex justify-space-between">
                    <div>
                        <div class="d-flex justify-start">
                            <img v-for="(picUrl, index) in coachDetail.portraits_before" 
                                :src="picUrl" 
                                :alt="`coach ${coachDetail.nickname} before ${index + 1}`" 
                                height="220"
                                class="ml-2"
                            >
                        </div>
                    </div>
                    <div>
                        <div class="d-flex justify-end">
                            <img v-for="(picUrl, index) in coachDetail.portraits_after" 
                                :src="picUrl" 
                                :alt="`coach ${coachDetail.nickname} after ${index + 1}`" 
                                height="220"
                                class="mr-2"
                            >
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-space-between mt-4">
                    <div class="pr-6 text-left" style="max-width: 50%;">
                        <div v-html="coachDetail.description.activity" :class="$style.common"></div>
                        <div v-html="coachDetail.description.before" :class="$style.common"></div>
                    </div>
                    <div class="pl-6 text-left" style="max-width: 50%;">
                        <div v-html="coachDetail.description.after" :class="$style.common"></div>
                    </div>
                </div>
                <div v-html="coachDetail.description.quote" :class="$style.quote"></div>
            </div>
            <div class="hidden-md-and-up mt-8">
                <div class="mb-4">
                    <div>
                        <img v-for="(picUrl, index) in coachDetail.portraits_before" 
                            :src="picUrl" 
                            :alt="`coach ${coachDetail.nickname} before ${index + 1}`" 
                            width="220"
                            height="300"
                            style="max-width: 100%; max-height: 100%; object-fit: cover; object-position: top;"
                            class="d-block mx-auto"
                        >
                    </div>
                </div>
                <div class="text-left mb-4">
                    <div v-html="coachDetail.description.activity" :class="$style.common"></div>
                    <div v-html="coachDetail.description.before" :class="$style.common"></div>
                </div>
                <div class="mb-4">
                    <div>
                        <img v-for="(picUrl, index) in coachDetail.portraits_after" 
                            :src="picUrl" 
                            :alt="`coach ${coachDetail.nickname} after ${index + 1}`" 
                            width="220"
                            height="300"
                            style="max-width: 100%; max-height: 100%; object-fit: cover; object-position: top;"
                            class="d-block mx-auto mb-4"
                        >
                    </div>
                </div>
                <div>
                    <div v-html="coachDetail.description.after" :class="$style.common"></div>
                    <div v-html="coachDetail.description.quote" :class="$style.quote"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="batch-container" v-if="this.coachDetail">
            <h5 style="color:rgba(136, 148, 100, 1)" class="text-h5">WAITING LIST</h5>
            <h6 style="color:rgba(136, 148, 100, 1)" class="text-subtitle-1 mb-4">{{ batchSentence }}</h6>
            <v-btn 
                class="w-75"
                flat
                :color="coachDetail.upcoming_batch != null ? 'rgba(255, 116, 84, 1)' : 'rgba(168, 168, 168, 1)' "
                :disabled="!coachDetail.upcoming_batch"
                @click="goToRegistrationPage"
            >
                JOIN AS MEMBER
            </v-btn>
        </div>
</template>

<script>
    import coachService from '@/services/coach/coachService.js'
    import TARGET_APP_URL from "@/config/target-web-app-config.js"
    import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
    import 'vue-lite-youtube-embed/style.css'

    export default {
        components:{
            LiteYouTubeEmbed
        },
        data(){
            return {
                coachDetail: null,
                batchSentence: '',
                TARGET_APP_URL: TARGET_APP_URL
            }
        },
        computed: {
            id() {
                return this.$route.params.id
            }
        },
        created(){
            this.loadData()
        },
        methods:{
            async loadData(){
                await this.fetchCoachDetail()
            },
            async fetchCoachDetail(){
                try {
                    const response = await coachService.getCoachDetail(this.id)

                    if(response.data.status == true){
                        this.coachDetail = response.data.data
                        this.batchSentence = this.coachDetail.program == 'Bahasa Indonesia'
                            ? `MULAI ${this.coachDetail.upcoming_batch[0].start_date}`
                            : `STARTING ${this.coachDetail.upcoming_batch[0].start_date}`
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            extractVideoID(videoUrl){
                const regex = /(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=))([^"&?\/\s]{11})/

                const match = videoUrl.match(regex)

                if (match && match[1]) {
                    var videoId = match[1]
                    return videoId
                } else {
                    console.log("Invalid YouTube URL")
                    return null
                }
            },
            goToRegistrationPage(){
                const url = `${this.TARGET_APP_URL}register?referralCode=${this.coachDetail.upcoming_batch[0].referral_code}`
                window.open(url, '_blank')

            },
        }
    }
</script>

<style>
    .bg-div{
        margin: 0px !important;
        padding: 20px;
        background-image: linear-gradient(to bottom, rgba(232, 228, 220, 1), rgba(136, 148, 100, 1));
    }
    .detail-container{
        max-width: 700px;
        margin:0px auto;
    }
    .batch-container{
        max-width: 700px;
        margin:60px auto;
    }
</style>
<style module>
    .common{
        color:rgba(232, 228, 220, 1);
        text-align: justify;
    }
    .common p {
        margin-bottom: 15px !important;
    }
    .quote{
        margin-top: 75px;
        color:rgba(232, 228, 220, 1);
        text-align: center;
    }
    .quote p{
        font-size: 20px;
        font-weight: 700;
    }
</style>
