<template>
    <div v-if="this.coachesSchedule">
        <div 
            class="mx-auto pa-6"
            style="max-width: 700px"
        >
            <v-select
                v-model="selectedDay"
                label="Filter By"
                :items="days_list"
                item-title="Day"
                item-value="ID"
                variant="outlined"
                density="compact"
                class="mt-8 w-25 hidden-sm-and-down"
            />
            <v-select
                v-model="selectedDay"
                label="Filter By"
                :items="days_list"
                item-title="Day"
                item-value="ID"
                variant="outlined"
                density="compact"
                class="mt-8 w-50 hidden-md-and-up"
            />

            <div
                v-for="([month, batches]) in Object.entries(coachesSchedule)"
                :key="month"
                class="mx-auto mb-3"
            >
                <v-card
                    variant="outlined"
                    class="pb-4 card-border"
                >
                    <v-card-title class="text-left text-subtitle-1 font-weight-bold">
                        {{ DateFormatter.formatDate({dateString:month, originFormat:'YYYY-MM', targetType:'LONG-MONTH'}) }}
                    </v-card-title>

                    <v-slide-group
                      show-arrows="always"
                    >
                        <v-slide-group-item
                            v-for="(batch, index) in batches"
                            :key="index"
                            v-slot="{ isSelected, toggle }"
                        >
                            <div 
                            v-if="batch.numeric_day == selectedDay || selectedDay == null"
                                class="pa-2"
                            >
                                <v-card
                                    :class="isSelected ? 'selected-card-border' : 'card-border'"
                                    max-width="150"
                                    variant="outlined"
                                    @click="toggle"
                                >
                                    <v-card-title>
                                        <p
                                            class="text-subtitle-1 font-weight-bold"
                                        >{{ DateFormatter.formatDate({dateString:batch.start_date, originFormat:'YYYY-MM-DD', targetType:'DAY-NAME'}) }}</p>
                                        <p
                                            class="text-subtitle-2"
                                        >{{ DateFormatter.formatDate({dateString:batch.start_date, originFormat:'YYYY-MM-DD', targetType:'SHORT-DATE'}) }}</p>
                                    </v-card-title>


                                    <v-img
                                    :src="batch.coach_detail.picture"
                                    height="100px"
                                    width="100px"
                                    cover
                                    class="mx-auto"
                                    ></v-img>

                                    <p class="pt-3 font-weight-bold">
                                        Coach {{batch.coach_detail.nickname}}
                                    </p>
                                    
                                    <v-btn
                                        v-if="isSelected"
                                        color="rgba(255, 116, 84, 1)"
                                        style="color:white"
                                        class="mt-3 mb-2 font-weight-bold"
                                        rounded="xl"
                                        size="small"
                                        variant="flat"
                                        @click="goToRegistrationPage(batch.referral_code)"
                                    >
                                        JOIN AS MEMBER
                                    </v-btn>
                                </v-card>
                            </div>
                        </v-slide-group-item>
                    </v-slide-group>

                </v-card>

            </div>

        </div>
    </div>
    <div v-else>
        <v-progress-circular
            color="rgba(136, 148, 100, 1)"
            indeterminate
            :size="100"
            class="mt-8"
        ></v-progress-circular>
    </div>
</template>

<script>
    import coachService from '@/services/coach/coachService.js'
    import DateFormatter from '@/utils/formatter/date-formatter.js'
    import TARGET_APP_URL from "@/config/target-web-app-config.js"

    export default {
        data(){
            return {
                coachesSchedule: null,
                selectedDay: null,
                days_list:[
                    {
                        Day: "All Day",
                        ID: null
                    },
                    {
                        Day: "Sunday",
                        ID: 0
                    },
                    {
                        Day: "Monday",
                        ID: 1
                    },
                    {
                        Day: "Tuesday",
                        ID: 2
                    },
                    {
                        Day: "Wednesday",
                        ID: 3
                    },
                    {
                        Day: "Thursday",
                        ID: 4
                    },
                    {
                        Day: "Friday",
                        ID: 5
                    },
                    {
                        Day: "Saturday",
                        ID: 6
                    }
                ],

                DateFormatter: DateFormatter,
                TARGET_APP_URL: TARGET_APP_URL
            }
        },
        created(){
            this.loadData()
        },
        methods:{
            async loadData(){
                await this.fetchCoachesSchedule()
            },
            async fetchCoachesSchedule(){
                try {
                    const response = await coachService.getCoachesSchedule()

                    if(response.data.status == true){
                        this.coachesSchedule = response.data.data.batches
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            goToRegistrationPage(referral_code){
                const url = `${this.TARGET_APP_URL}register?referralCode=${referral_code}`
                window.open(url, '_blank')

            }
            
        }
    }
</script>
<style scoped>
   .card-border{
        border:1px solid lightgray !important;
        padding:6px;
   }
   .selected-card-border{
        border:3px solid rgba(136, 148, 100, 1) !important;
        padding:6px;
   }
</style>