import axios from "axios"
import BASE_URL from "@/config/api-config.js"

export default {
    async getCoachList(){
        try {
            const response = await axios.get(`${BASE_URL}/coach_detail/all`)

            return response
        } catch (error) {
            throw error
        }
    },
    async getFeaturedCoaches(){
        try {
            const response = await axios.get(`${BASE_URL}/coach_detail/feature`)

            return response
        } catch (error) {
            throw error
        }
    },
    async getCoachDetail(id){
        try {
            const response = await axios.get(`${BASE_URL}/coach_detail/${id}`)

            return response
        } catch (error) {
            throw error
        }
    },
    async getCoachesSchedule(){
        try {
            const response = await axios.get(`${BASE_URL}/coach_detail/schedule`)

            return response
        } catch (error) {
            throw error
        }
    }
}