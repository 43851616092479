import List from "./List.vue"
import Detail from "./Detail.vue"
import Feature from "./Feature.vue"
import ScheduleEN from "./Schedule-EN.vue"
import ScheduleID from "./Schedule-ID.vue"

const routes = [
    { path:"/coach/all", component:List},
    { path:"/coach/feature", component:Feature},
    { path:"/coach/schedule-EN", component:ScheduleEN},
    { path:"/coach/schedule-ID", component:ScheduleID},
    { path:"/coach/detail/:id", component:Detail}
]

export default routes